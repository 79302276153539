import React, { Component } from "react";
import PopOverForm from './forms/popover-form.js';
import { NavLink } from "react-router-dom";
import DelayForm from './forms/delay-form.js';

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      popUp: false,
      newsletterPop: 'No',
      delayFilled: false,
      delayName: '',
      delayEmail: ''
    }
  }
  openPop = (e) => {
    let popName = 'startedPop';
    document.getElementById(popName).classList.add('active-pop');
  }
  animateBack = (e) =>{
    // document.getElementById('siteContainer').classList.add('animateBack');
  }
  comingSoon = (e) => {
    e.preventDefault();
    alert('Coming Soon!')
  }
  closePop = (e) => {
    let popName = 'startedPop';
    document.getElementById(popName).classList.remove('active-pop');
    this.setState({
      newsletterPop: 'Yes'
    })
    sessionStorage.setItem('newsletter', 'inactive');
    // document.getElementById('siteContainer').classList.add('animateBack');
  }
  closeToPop = (e) => {
    document.body.classList.remove('active-timeout');
    this.setState({
      newsletterPop: 'Yes',
      delayFilled: false
    })
    sessionStorage.setItem('newsletter', 'inactive');
    // document.getElementById('siteContainer').classList.add('animateBack');
  }
  timerInt = (e) => {
    let timerStart = 0;
    let newsletterStore = sessionStorage.getItem('newsletter');
    setInterval(function(){
      if(sessionStorage.getItem('newsletter') !== 'inactive'){
        // console.log(sessionStorage.getItem('newsletter'));
        timerStart++;
        // console.log(timerStart);
        if(timerStart > 10){
          document.body.classList.add('active-timeout');
          clearInterval(timerStart);
        }
        document.addEventListener('mousemove', function(){
          timerStart = 0;
        })
      }
    }, 1000)
  }
  render() {
    this.timerInt();
    const navitems = [
      { item: "About", url: "/about" },
    ];
    return (
      <>
        <footer className="siteFooter">
          <div className="grid-x grid-padding-x grid-margin-x">
            <div className="large-4 large-order-1 cell">
              <h2>Get in Touch</h2>
              <p><a href="mailto:hello@weareoneseven.com">hello@weareoneseven.com</a><br/>
              <a href="tel:(216) 865-1700">(216) 865-1700</a></p>
            </div>
            <div className="large-4 large-order-3 cell">
              <h2>Basecamp</h2>
              <p>24400 Chagrin Blvd., Suite 200<br/>
              Beachwood, OH 44122</p>
            </div>
            <div className="large-4 large-order-2 cell">
              <img src="/images/footer-logo.png" alt="" />
              <p><a className="lmg-fab lmg-fa-facebook-f" href="https://www.facebook.com/OneSevenAdvisors" target="_blank"></a> <a className="lmg-fab lmg-fa-linkedin-in" target="_blank" href="https://www.linkedin.com/company/we-are-one-seven"></a></p>
              <p><a className="button" href="#" onClick={this.comingSoon}>Advisor Portal</a></p>              
              <p><NavLink to="/book" className="button book-button">Download Our Book</NavLink></p>
              <p className="small">Copyright &copy; 2020 One Seven</p>
              <p className="small">We Are One Seven, LLC (“One Seven”) is a registered investment advisor with the U.S. Securities and Exchange Commission (“SEC”) (registration does not imply a certain level of skill or training).​</p>
            </div>
          </div>
        </footer>
        <div className="pop-up-container" id="startedPop">
        <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          <div className="left-side grid-x">
            <div>
              <h2>Learn More</h2>
              <p>If you’re thinking about making a move and would like to<br /> ask some questions or discuss your situation, we’d love to hear from you.</p>
              <div className="contact-container grid-x">
                <div className="large-12 medium-6 cell">
                  <h3>Phone</h3>
                  <p><a href="tel:2168651700">(216) 865-1700</a></p>
                </div>
                <div className="large-12 medium-6 cell">
                  <h3>Email</h3>
                  <p><a href="mailto:hello@weareoneseven.com">hello@weareoneseven.com</a></p>
                </div>
                <div className="large-12 medium-12 cell">
                  <h3>Basecamp</h3>
                  <p>24400 Chagrin Blvd., Suite 200<br/>
                  Beachwood, OH 44122</p>
                </div>
              </div>
            </div>
          </div>
          <div className="right-side grid-x show-for-large">
            <div>
              <PopOverForm filled={this.state.newsletterPop} />
            </div>
          </div>
        </div>
        <div className="inactive-pop" id="toPop">
        <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closeToPop}></i>
          <h2>Fill out the form below, and we'll send you some more information!</h2>
          <DelayForm filled={this.state.popUp} />
        </div>
        <div className="inactive-cast"></div>
      </>
    );
  }
}
